<template>
  <div class="noauth-layout">
    <NewVersionBanner v-if="appStore.newVersionAvailable" />
    <div v-else>
      <LoaderDialog v-if="appStore.isLoading" />
      <div
        v-if="appStore.hasUserInfo() || appStore.embedPublicUser"
        class="noauth-layout_container"
      >
        <HeaderBar />
        <div class="d-flex">
          <NavBar v-if="appStore.hasUserInfo()" />
          <div class="w-100 noauth-layout_router">
            <ExpiryBanner />
            <slot />
          </div>
        </div>
      </div>
      <TextCopiedModal ref="textCopyModal" />
    </div>
  </div>
</template>
<script setup lang="ts">
  // import type { AxiosInstance } from 'axios'
  import ExpiryBanner from '~/components/ExpiryBanner.vue'

  // Store
  const appStore = useAppStore()
  const schemaStore = useSchemaStore()

  // Properties
  const textCopyModal: Ref<any> = ref(null)

  // Computed Properties
  const copiedCode = computed(() => {
    return schemaStore.copiedCode
  })

  // Hooks

  // Watchers
  watch(copiedCode, () => {
    textCopyModal.value.show(copiedCode)
  })
</script>
<style scoped lang="scss">
  @import '@/assets/scss/_color-palette.scss';
  .noauth-layout {
    font-family: 'Libre Franklin';
    &_container {
      background-color: $midnight-blue;
    }
    // &_banner-wrapper {
    //   display: flex;
    //   justify-content: center;
    //   .p-message {
    //     border-radius: 0;
    //     outline-style: none;
    //     width: 60rem;
    //     padding: 1rem;
    //     background-color: $compile-banner-color;
    //     color: $white;
    //   }
    //   .message-container {
    //     display: flex;
    //     width: 57rem;
    //     justify-content: space-between;
    //     align-items: center;
    //     & .btn-outline-primary {
    //       color: $compile-blue-dark;
    //       border-color: $compile-blue-dark;
    //       &:hover {
    //         color: $white;
    //       }
    //     }
    //   }
    // }
  }
</style>
